import React, { forwardRef } from 'react';
import { cn } from 'src/lib/cn';

type Props = React.PropsWithChildren<{
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onContainerClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickCapture?: (event: React.MouseEvent<HTMLDivElement>) => void;
  selected?: boolean;
  cardContentClassName?: string;
  className?: string;
}>;

const BaseCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      onKeyDown,
      onContainerClick,
      onClickCapture,
      selected,
      cardContentClassName,
      className,
    },
    ref
  ): JSX.Element => {
    return (
      <div
        ref={ref}
        className={cn(
          'group flex rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-600 focus-visible:ring-offset-2',
          className
        )}
        tabIndex={0}
        onKeyDown={onKeyDown}
        role="button"
        onClickCapture={onClickCapture}
        onClick={onContainerClick}
      >
        <div
          className={cn(
            'fade-in-out flex h-auto max-h-fit min-w-0 flex-auto select-none flex-col gap-3 rounded-lg border-2 border-solid bg-white p-3 shadow shadow-primary/15 transition-colors hover:bg-gray-50 focus-visible:outline-0',
            selected ? 'border-primary' : 'border-transparent',
            cardContentClassName
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);

BaseCard.displayName = 'BaseCard';

export default BaseCard;
