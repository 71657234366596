import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import {
  Heading,
  Input as AriaInput,
  Button,
  Checkbox,
} from 'react-aria-components';
import { Icon } from 'src/components/Icon';
import { trpc } from 'src/lib/trpc';
import SearchMd from 'src/assets/svgicons/line/search-md.svg';
import Trash01 from 'src/assets/svgicons/line/trash-01.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import InfoCircle from 'src/assets/svgicons/line/info-circle.svg';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from 'src/components/Popover/AriaPopover';
import Spinner from 'src/components/Loaders/Spinner';
import { cn } from 'src/lib/cn';
import Input from 'src/components/Input';
import { useI18nContext } from 'src/i18n/i18n-react';
import { BaseModal } from 'src/components/AriaModal';
import { DeleteTagCategoryModal } from '../DeleteTagCategoryModal';

interface CreateOrEditTagModalProps {
  accountUuid: string;
  adUuids: string[];
  onClose: () => void;
  editTagUuid?: string;
}

export const CreateOrEditTagModal: React.FC<CreateOrEditTagModalProps> = ({
  accountUuid,
  adUuids,
  onClose,
  editTagUuid,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const trpcUtils = trpc.useUtils();

  const tagTaxonomy = trpc.insightsTags.getTagTaxonomyForAccount.useQuery({
    accountUuid,
  });

  const createTag = trpc.insightsTags.createTag.useMutation({
    async onSuccess() {
      await trpcUtils.insightsTags.getTags.invalidate();
      await trpcUtils.insightsTags.getTagsForAd.invalidate();
      await trpcUtils.insightsTags.getTagTaxonomyForAccount.invalidate();
      await trpcUtils.insightsAds.getManyAdGroupsWithTags.invalidate();
      setIsOpen(false);
    },
  });

  const updateTag = trpc.insightsTags.updateTag.useMutation({
    async onSuccess() {
      await trpcUtils.insightsTags.getTags.invalidate();
      await trpcUtils.insightsTags.getTagsForAd.invalidate();
      await trpcUtils.insightsTags.getTagTaxonomyForAccount.invalidate();
      await trpcUtils.insightsAds.getManyAdGroupsWithTags.invalidate();
      setIsOpen(false);
    },
  });

  const categories = Object.values(tagTaxonomy.data?.categories ?? {});

  const inputRef = useRef<HTMLInputElement | null>(null);

  const defaultTag = editTagUuid
    ? tagTaxonomy.data?.tags[editTagUuid]
    : undefined;

  const defaultTagCategoryUuid = defaultTag?.categoryUuid;

  const defaultCategory = defaultTagCategoryUuid
    ? (tagTaxonomy.data?.categories[defaultTagCategoryUuid] ?? null)
    : null;

  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    uuid: string;
  } | null>(defaultCategory);

  useEffect(() => {
    if (
      tagTaxonomy.data &&
      selectedCategory &&
      !tagTaxonomy.data.categories[selectedCategory.uuid]
    ) {
      setSelectedCategory(null);
    }
  }, [tagTaxonomy.data, selectedCategory]);

  const defaultLabel = defaultTag?.label;
  const defaultTagAdCount = defaultTag?.numAds;

  return (
    <BaseModal
      show={isOpen}
      afterClose={onClose}
      onClose={() => setIsOpen(false)}
      dialogClassName="relative flex h-full flex-col rounded-2xl bg-white"
    >
      <div className="border-b border-solid border-purple-200 px-6 py-4">
        <Heading className="text-xl font-semibold text-primary">
          {editTagUuid ? `Edit '${defaultLabel}' Tag` : 'Create New Tag'}
        </Heading>
      </div>
      <div className="flex flex-col gap-8 border-b border-solid border-purple-200 px-6 py-4">
        <div className="flex flex-row gap-4">
          <Input
            ref={inputRef}
            name="tag-name"
            label="Tag Name"
            defaultValue={defaultLabel}
            placeholder="Tag Name"
            containerClassName="basis-1/2"
          />

          <CategoryPopover
            selectedCategory={selectedCategory}
            setSelectedCategory={(cat) => setSelectedCategory(cat)}
            accountUuid={accountUuid ?? ''}
            categories={categories}
          />
        </div>
        {!!defaultTagAdCount && defaultTagAdCount > 1 && (
          <div className="flex flex-row gap-3 rounded-lg bg-yellow-50 pb-3 pl-3 pr-6 pt-2.5 text-xs text-yellow-800">
            <Icon className="size-4 shrink-0">
              <InfoCircle />
            </Icon>
            <div className="space-y-1">
              <div className="text-sm font-semibold">
                Tag modification will affect multiple ads
              </div>
              <div className="text-xs">
                This tag is applied to {defaultTagAdCount} ads. All instances
                will be updated to reflect your changes.
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2.5 px-6 py-4">
        <MagicBriefButton variant="secondary" onPress={() => setIsOpen(false)}>
          Cancel
        </MagicBriefButton>
        <MagicBriefButton
          loading={createTag.isLoading || updateTag.isLoading}
          onPress={() => {
            if (inputRef.current?.value) {
              if (editTagUuid) {
                updateTag.mutate({
                  accountUuid,
                  tagUuid: editTagUuid,
                  category: selectedCategory
                    ? { label: selectedCategory.label }
                    : null,
                  label: inputRef.current.value,
                });
              } else {
                createTag.mutate({
                  accountUuid,
                  tag: { label: inputRef.current.value },
                  category: selectedCategory
                    ? { label: selectedCategory.label }
                    : undefined,
                  adUuids,
                });
              }
            }
          }}
          size="small"
        >
          {editTagUuid ? 'Save Changes' : 'Create'}
        </MagicBriefButton>
      </div>
    </BaseModal>
  );
};

const CategoryPopover: React.FC<{
  accountUuid: string;
  categories: Array<{ uuid: string; label: string }>;
  selectedCategory: { uuid: string; label: string } | null;
  setSelectedCategory: (
    category: { uuid: string; label: string } | null
  ) => void;
}> = ({ accountUuid, categories, setSelectedCategory, selectedCategory }) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  const createCategory = trpc.insightsTags.createCategory.useMutation({
    onSuccess: async () => {
      setQuery('');
      await trpcUtils.insightsTags.getTags.invalidate();
      await trpcUtils.insightsTags.getTagTaxonomyForAccount.invalidate();
    },
    onError: (e, opts) => {
      toast.error(e instanceof Error ? e.message : 'Unknown error');
      Sentry.captureException(e, { contexts: { opts } });
    },
  });

  return (
    <div className="flex-1 space-y-0.5">
      <div className="text-xs font-semibold text-primary sm:text-sm">
        Category
      </div>
      <PopoverTrigger isOpen={isOpen} onOpenChange={(s) => setIsOpen(s)}>
        <Button
          onPress={() => setIsOpen(true)}
          className={cn(
            'box-border flex h-10 w-full items-center justify-between rounded-md border border-solid border-purple-300 bg-white px-3 py-2 text-left text-sm text-primary shadow',
            selectedCategory ? 'text-primary' : 'text-primary/50'
          )}
        >
          <span className={selectedCategory ? undefined : 'italic'}>
            {selectedCategory ? selectedCategory.label : 'Uncategorized'}
          </span>
          <Icon>
            <ChevronDown />
          </Icon>
        </Button>
        <Popover className="flex w-[400px] flex-col overflow-hidden">
          <PopoverDialog className="flex flex-col overflow-hidden p-0">
            <div className="group flex items-center gap-2 border-b border-solid border-b-purple-200 px-6 py-4">
              {createCategory.isLoading ? (
                <Spinner className="size-5 text-primary" />
              ) : (
                <Icon className="size-5 text-[#917DD2] transition-colors duration-200 group-focus-within:text-primary">
                  <SearchMd />
                </Icon>
              )}

              <AriaInput
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && query.length > 0) {
                    createCategory.mutate({
                      accountUuid,
                      category: { label: query },
                    });
                  }
                }}
                className={cn(
                  /* Base */
                  'text-md peer w-full flex-1 text-[#917DD2] transition-colors duration-200 focus:outline-none',
                  /* Focus */
                  'focus:text-primary',
                  /* Placeholder */
                  'placeholder:text-md placeholder:font-medium placeholder:text-[#917DD2]'
                )}
                placeholder={LL.insights.tags.categorySearchPlaceholder()}
              />

              <Button
                className={cn(
                  'invisible flex items-center gap-0.5 rounded-[4px] border border-solid border-purple-50 bg-purple-50 py-0.5 pl-1.5 pr-1 opacity-0 transition-all duration-300 focus:outline-none',
                  'hover:border-purple-300',
                  query && 'visible opacity-100'
                )}
                onPress={() =>
                  createCategory.mutate({
                    accountUuid,
                    category: { label: query },
                  })
                }
              >
                <span className="text-xs font-normal text-primary">
                  {LL.insights.tags.enter()}
                </span>
                <Icon className="size-4 text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                    className="opacity-80"
                  >
                    <path
                      d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144-56 56Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Icon>
              </Button>
            </div>

            <div className="flex flex-col gap-2 overflow-y-auto p-3">
              <CategoryList
                accountUuid={accountUuid}
                categories={categories}
                selected={selectedCategory}
                setSelected={(s) => setSelectedCategory(s)}
                query={query}
              />
            </div>

            {/* Footer */}
            <div className="flex items-center justify-end gap-2 border-t border-solid border-t-purple-200 px-6 py-3">
              <MagicBriefButton
                className="w-fit"
                onPress={() => setIsOpen(false)}
                variant="secondary"
                size="small"
              >
                {LL.done()}
              </MagicBriefButton>
            </div>
          </PopoverDialog>
        </Popover>
      </PopoverTrigger>
    </div>
  );
};

interface CategoryListProps {
  accountUuid: string;
  selected: { label: string; uuid: string } | null;
  setSelected: (selected: { label: string; uuid: string } | null) => void;
  query: string;
  categories: Array<{ uuid: string; label: string }>;
}

const CategoryList: React.FC<CategoryListProps> = ({
  accountUuid,
  selected,
  setSelected,
  query,
  categories,
}) => {
  const { LL } = useI18nContext();
  const [deleteCategoryUuid, setDeleteCategoryUuid] = useState<string | null>(
    null
  );
  const searchResultCategories = categories.filter((x) =>
    x.label.toLowerCase().includes(query.toLowerCase())
  );

  const hasNoResults = searchResultCategories.length === 0 && query.length > 0;

  if (hasNoResults) {
    return (
      <div className="flex h-[300px] items-center justify-center">
        <span className="text-md font-medium text-[#917DD2]">
          {LL.insights.tags.noCategoriesFound()}
        </span>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {categories.length > 0 && (
        <div className="space-y-1">
          <span className="text-xs font-medium text-[#917DD2]">
            {LL.insights.tags.categories({
              count: searchResultCategories.length,
            })}
          </span>
          <ul aria-label="insights tags">
            {searchResultCategories.map((cat) => {
              return (
                <li
                  key={cat.uuid}
                  className="group flex items-center justify-between rounded-md pr-2 hover:bg-purple-50 focus:outline-none"
                >
                  <Checkbox
                    isSelected={selected?.uuid === cat.uuid}
                    className="group/checkbox flex flex-1 cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                    onChange={(s) => {
                      setSelected(s ? cat : null);
                    }}
                  >
                    <div
                      className={cn(
                        /** Base */
                        'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                        /** Selected */
                        'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
                      )}
                    >
                      <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                        <Check />
                      </Icon>
                    </div>
                    <span className="text-sm font-medium text-primary">
                      {cat.label}
                    </span>
                  </Checkbox>

                  <Button
                    className="invisible rounded-md p-2 hover:bg-red-100 group-hover:visible focus:outline-none pressed:bg-red-200"
                    onPress={() => {
                      setDeleteCategoryUuid(cat.uuid);
                    }}
                  >
                    <Icon className="size-4 text-danger">
                      <Trash01 />
                    </Icon>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {!!deleteCategoryUuid && (
        <DeleteTagCategoryModal
          onClose={() => setDeleteCategoryUuid(null)}
          accountUuid={accountUuid}
          categoryUuid={deleteCategoryUuid}
        />
      )}
    </div>
  );
};
