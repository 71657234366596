import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Icon } from 'src/components/Icon';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import AriaModal from 'src/components/AriaModal/AriaModal';
import { AriaButton } from 'src/components/Button';
import { trpc } from 'src/lib/trpc';

interface DeleteTagModalProps {
  tagUuid: string;
  accountUuid: string;
  onClose: () => void;
}

export const DeleteTagModal: React.FC<DeleteTagModalProps> = ({
  tagUuid,
  accountUuid,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const tagTaxonomy = trpc.insightsTags.getTagTaxonomyForAccount.useQuery({
    accountUuid,
  });

  const trpcUtils = trpc.useUtils();

  const deleteTag = trpc.insightsTags.deleteTags.useMutation({
    onSuccess: async () => {
      await trpcUtils.insightsTags.getTags.invalidate();
      await trpcUtils.insightsTags.getTagsForAd.invalidate();
      await trpcUtils.insights.getRecentlyUsedInsightsTags.invalidate();
      await trpcUtils.insightsAds.getManyAdGroupsWithTags.invalidate();
      await trpcUtils.insightsTags.getTagTaxonomyForAccount.invalidate();
      setIsOpen(false);
    },
    onError: (e, opts) => {
      toast.error(e instanceof Error ? e.message : 'Unknown error');
      Sentry.captureException(e, { contexts: { opts } });
    },
  });

  const [label] = useState(tagTaxonomy.data?.tags[tagUuid]?.label);
  const [numAds] = useState(tagTaxonomy.data?.tags[tagUuid]?.numAds);

  return (
    <AriaModal
      footer={
        <div className="flex flex-row justify-end gap-2.5">
          <AriaButton variant="white" onPress={() => setIsOpen(false)}>
            Cancel
          </AriaButton>
          <AriaButton
            loading={deleteTag.isLoading}
            colour="danger"
            variant="secondary"
            onPress={() => {
              deleteTag.mutate({
                accountUuid,
                tagUuids: [tagUuid],
              });
            }}
          >
            Permanently Delete Tag
          </AriaButton>
        </div>
      }
      show={isOpen}
      onClose={() => setIsOpen(false)}
      afterClose={onClose}
      title={`Delete '${label}' Tag`}
    >
      <div className="space-y-4">
        <div className="text-sm text-text-secondary">
          Are you sure you want to delete this tag?
        </div>
        <div className="flex flex-row gap-3 rounded-lg bg-red-50 pb-3 pl-3 pr-6 pt-2.5 text-xs text-red-700">
          <Icon className="size-4 shrink-0">
            <AlertTriangle />
          </Icon>
          <div>
            {`This tag will be permanently deleted${numAds ? ` and removed${numAds > 1 ? ` from all ${numAds} ads where it is currently applied` : ''}` : ''}. This cannot be undone.`}
          </div>
        </div>
      </div>
    </AriaModal>
  );
};
