import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { Disclosure, Transition } from '@headlessui/react';
import { EventTarget } from '@magicbrief/server/src/services/analytics/analytics';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import Menu01 from 'src/assets/svgicons/duocolor/menu-01.svg';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import MagicBriefLogoHalfColoured from 'src/assets/svgicons/magicbrief/MagicBriefLogoHalfColoured.svg';
import UserPlus01 from 'src/assets/svgicons/line/user-plus-01.svg';
import NotificationsPopup from 'src/components/Notifications';
import { useWhiteLabel } from 'src/utils/useWhiteLabel';
import { showFeaturesModalAtom } from 'src/pages/LibraryV2/Library.atoms';
import { BaseButton, BaseButtonProps } from '../Button';
import { AriaButton, ButtonIcon } from '../Button/Button';
import UserMenuDropdown from '../UserMenuDropdown';
import InviteTeamModal from '../InviteTeamModal/InviteTeamModal';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import {
  NavigationContext,
  useDefaultNavigationLinks,
  useNavigationContext,
} from './hooks';

type NavigationSectionProps = {
  className?: string;
};

const NavigationSection: React.FunctionComponent<
  React.PropsWithChildren<NavigationSectionProps>
> = ({ children, className }) => {
  return (
    <div
      className={classNames(className, 'flex flex-row items-center gap-2.5')}
    >
      {children}
    </div>
  );
};

const baseNavigationItemStyle =
  'text-white font-bold text-sm px-3 py-3 rounded-lg h-12 disabled:opacity-50 disabled:cursor-not-allowed';

type NavigationButtonProps = {
  variant?: 'normal' | 'custom';
};

const NavigationButton = forwardRef<
  HTMLButtonElement,
  BaseButtonProps & NavigationButtonProps
>(({ variant = 'normal', className, icon, children, ...props }, ref) => {
  return (
    <BaseButton
      {...props}
      ref={ref}
      icon={icon ? <ButtonIcon size="normal">{icon}</ButtonIcon> : null}
      className={classNames(
        baseNavigationItemStyle,
        variant === 'normal' ? 'bg-purple-800 hover:bg-purple-700' : '',
        icon && !children ? 'w-12' : '',
        className
      )}
    >
      {children}
    </BaseButton>
  );
});

NavigationButton.displayName = 'NavigationButton';

type NavigationNavLinkProps = {
  to: string;
};

const baseNavigationLinkStyle = `px-4 h-9 text-white font-semibold leading-7 flex rounded-full justify-center items-center group-[.mobile]:h-9 group-[.mobile]:justify-start`;

const NavigationSkeletonNavLink: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  return <div className={baseNavigationLinkStyle}>{children}</div>;
};

const NavigationNavLink: React.FunctionComponent<
  React.PropsWithChildren<NavigationNavLinkProps>
> = ({ children, to }) => {
  const whiteLabelData = useWhiteLabel();

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          baseNavigationLinkStyle,
          whiteLabelData?.secondaryColor
            ? {
                'bg-[var(--wl-secondary-color)]': isActive,
                'hover:bg-[var(--wl-secondary-color)]': !isActive,
              }
            : {
                'bg-purple-800': isActive,
                'hover:bg-purple-800': !isActive,
              }
        )
      }
      style={
        {
          '--wl-secondary-color': whiteLabelData?.secondaryColor,
        } as React.CSSProperties
      }
    >
      {children}
    </NavLink>
  );
};

const NavigationDefaultLinks: React.FunctionComponent = () => {
  const userAndOrg = useUserAndOrganisation();
  const isTrialExpired = userAndOrg.data?.planStatus === 'trial_expired';
  const setShowFeaturesModal = useSetAtom(showFeaturesModalAtom);
  const links = useDefaultNavigationLinks();
  return (
    <div className="flex flex-row gap-2">
      {links.map(({ to, label }) => {
        let target: string | undefined;
        let eventTarget: EventTarget | undefined;
        switch (to) {
          case '/library':
            target = 'n_inspire';
            eventTarget = 'Paywall Inspire';
            break;
          case '/briefs':
            target = 'n_briefs';
            eventTarget = 'Paywall Briefs';
            break;
          case '/assets':
            target = 'n_assets';
            eventTarget = 'Paywall Assets';
            break;
          case '/insights':
            target = 'n_insights';
            eventTarget = 'Paywall Insights';
            break;
        }

        if (isTrialExpired && to !== '/library') {
          return (
            <div key={to} data-intercom-target={target}>
              <AriaButton
                className="!rounded-full border-transparent bg-transparent !text-base"
                onPress={() =>
                  setShowFeaturesModal({
                    isOpen: true,
                    target: eventTarget,
                  })
                }
              >
                {label}
              </AriaButton>
            </div>
          );
        }
        return (
          <div key={to} data-intercom-target={target}>
            <NavigationNavLink to={to}>{label}</NavigationNavLink>
          </div>
        );
      })}
    </div>
  );
};

const NavigationSkeletonDefaultLinks: React.FunctionComponent = () => {
  const links = useDefaultNavigationLinks();
  return (
    <div className="flex flex-row gap-2">
      {links.map(({ to, label }) => (
        <NavigationSkeletonNavLink key={to}>
          <span className="animate-pulse rounded-full bg-white/20">
            <span aria-hidden className="invisible">
              {label}
            </span>
          </span>
        </NavigationSkeletonNavLink>
      ))}
    </div>
  );
};

const NavigationDefaultMobileLinks: React.FunctionComponent = () => {
  const links = useDefaultNavigationLinks();
  const { LL } = useI18nContext();
  const [showInviteModal, setShowInviteModal] = useState(false);
  return (
    <div className="flex flex-col gap-3">
      {links.map(({ to, label }) => (
        <NavigationNavLink key={to} to={to}>
          {label}
        </NavigationNavLink>
      ))}
      <NavigationButton
        variant="custom"
        onClick={() => setShowInviteModal(true)}
      >
        <span className="flex flex-row items-center gap-2">
          <span className="hidden lg:block">{LL.invite()}</span>
          <Icon>
            <UserPlus01 />
          </Icon>
        </span>
      </NavigationButton>
      <InviteTeamModal
        show={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
    </div>
  );
};

const NavigationDefaultLead: React.FunctionComponent = () => {
  const { isMobileMenuOpen } = useNavigationContext();
  const { LL } = useI18nContext();
  const user = useUserAndOrganisation();
  const whiteLabelData = useWhiteLabel();

  return (
    <div className="flex flex-row items-center gap-3">
      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white lg:hidden">
        <span className="sr-only">{LL.navigation.openMenu()}</span>
        {isMobileMenuOpen ? (
          <Icon className="block size-6" aria-hidden="true">
            <XClose />
          </Icon>
        ) : (
          <Icon className="block size-6" aria-hidden="true">
            <Menu01 />
          </Icon>
        )}
      </Disclosure.Button>
      <div
        className={classNames(
          'hidden h-12 w-12 items-center justify-center overflow-hidden rounded-lg lg:flex',
          {
            'bg-purple-500': !whiteLabelData?.logoURL,
          }
        )}
      >
        {whiteLabelData?.logoURL ? (
          <a
            href={whiteLabelData.domain ?? '/'}
            target={whiteLabelData.domain && '_blank'}
            rel="noopener noreferrer"
            className="h-full rounded-lg border border-solid"
            style={{
              borderColor: whiteLabelData?.secondaryColor,
            }}
          >
            <img
              className="size-full rounded-md object-cover"
              src={whiteLabelData.logoURL}
              alt="White label logo"
            />
          </a>
        ) : user.data?.organisation.profilePicURL ? (
          <img
            alt=""
            className="h-full object-cover"
            src={user.data.organisation.profilePicURL}
          />
        ) : (
          <div className="text-white">
            <MagicBriefBolt />
          </div>
        )}
      </div>
    </div>
  );
};

type NavigationBaseProps = React.PropsWithChildren<{
  mobileMenu: React.ReactNode | null;
  staticPosition?: boolean;
  className?: string;
  style?: React.CSSProperties;
}>;

const NavigationBase: React.FunctionComponent<NavigationBaseProps> = ({
  mobileMenu,
  children,
  staticPosition,
  className,
  style,
}) => {
  const whiteLabelData = useWhiteLabel();

  return (
    <Disclosure
      as="nav"
      className={classNames(
        staticPosition ? 'static' : 'sticky top-0',
        'z-300 w-full bg-purple-900'
      )}
      style={{
        backgroundColor: whiteLabelData?.themeColor,
        ...style,
      }}
    >
      {({ open }) => (
        <NavigationContext.Provider value={open}>
          <div
            className={classNames(
              className,
              'flex h-[72px] flex-row items-center justify-between px-5 py-2'
            )}
          >
            {children}
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform -translate-y-4 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform tranlsate-y-0 opacity-100"
            leaveTo="transform -translate-y-4 opacity-0"
          >
            {mobileMenu && (
              <Disclosure.Panel className="mobile group px-6 pb-6 pt-3 transition-all lg:hidden">
                {mobileMenu}
              </Disclosure.Panel>
            )}
          </Transition>
        </NavigationContext.Provider>
      )}
    </Disclosure>
  );
};

type NavigationProps = React.PropsWithChildren<{
  customSections?: React.ReactNode | null;
  guest?: boolean;
}>;

type NavigationComposite = {
  Section: React.FunctionComponent<
    React.PropsWithChildren<NavigationSectionProps>
  >;
  Button: typeof NavigationButton;
  Link: React.FunctionComponent<
    React.PropsWithChildren<NavigationNavLinkProps>
  >;
  DefaultLinks: React.FunctionComponent;
  SkeletonLinks: React.FunctionComponent;
  DefaultLead: React.FunctionComponent;
  Base: React.FunctionComponent<NavigationBaseProps>;
};

const Navigation: React.FunctionComponent<NavigationProps> &
  NavigationComposite = ({ children, customSections, guest }) => {
  const { LL } = useI18nContext();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const whiteLabelData = useWhiteLabel();

  return (
    <NavigationBase
      className={
        whiteLabelData?.secondaryColor
          ? '[&_button]:bg-[var(--wl-secondary-color)] [&_button]:hover:bg-[var(--wl-secondary-color)]'
          : ''
      }
      style={
        {
          '--wl-secondary-color': whiteLabelData?.secondaryColor,
        } as React.CSSProperties
      }
      mobileMenu={<NavigationDefaultMobileLinks />}
    >
      {guest ? (
        whiteLabelData?.logoURL ? (
          <a
            href={
              whiteLabelData.domain
                ? new URL(whiteLabelData.domain).toString()
                : '/'
            }
            target={whiteLabelData.domain && '_blank'}
            rel="noopener noreferrer"
            className="size-12 rounded-lg border border-solid"
            style={{
              borderColor: whiteLabelData?.secondaryColor,
            }}
          >
            <img
              className="size-full rounded-md object-cover"
              src={whiteLabelData.logoURL}
              alt="White label logo"
            />
          </a>
        ) : (
          <>
            <a href="/" className="hidden h-7 lg:block">
              <MagicBriefLogoHalfColoured className="hidden h-full max-w-[143px] lg:block" />
            </a>
            <div className="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-purple-500 lg:hidden">
              <div className="text-white">
                <Icon>
                  <MagicBriefBolt />
                </Icon>
              </div>
            </div>
          </>
        )
      ) : (
        <div className="flex flex-row items-center">
          <NavigationDefaultLead />
          <div className="hidden flex-row items-center gap-3 px-6 lg:flex">
            <NavigationDefaultLinks />
          </div>
        </div>
      )}
      {customSections}
      <Navigation.Section>
        {guest && !whiteLabelData && (
          <div className="flex items-center gap-4">
            <p className="hidden text-sm font-medium text-white lg:block">
              {LL.libraryTryEntice()}
            </p>
            <a className="no-underline" href="https://magicbrief.com">
              <Navigation.Button className="w-[137px]">
                {LL.try()}
              </Navigation.Button>
            </a>
          </div>
        )}
        {children}
        {!guest && (
          <>
            <NavigationButton
              variant="custom"
              onClick={() => setShowInviteModal(true)}
              className="hidden bg-purple-800 lg:block"
              data-intercom-target="n_invite"
            >
              <span className="flex flex-row items-center gap-2">
                <span className="hidden lg:block">{LL.invite()}</span>
                <Icon>
                  <UserPlus01 />
                </Icon>
              </span>
            </NavigationButton>
            <InviteTeamModal
              show={showInviteModal}
              onClose={() => setShowInviteModal(false)}
            />
          </>
        )}
        {!guest && getFeatureFlagValue('NOTIFICATIONS_IN_APP') && (
          <NotificationsPopup />
        )}
        {!guest && <UserMenuDropdown />}
      </Navigation.Section>
    </NavigationBase>
  );
};

Navigation.Section = NavigationSection;
Navigation.Button = NavigationButton;
Navigation.Link = NavigationNavLink;
Navigation.SkeletonLinks = NavigationSkeletonDefaultLinks;
Navigation.DefaultLinks = NavigationDefaultLinks;
Navigation.DefaultLead = NavigationDefaultLead;
Navigation.Base = NavigationBase;

export default Navigation;
