import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { trpc } from 'src/lib/trpc';
import PlusCircle from 'src/assets/svgicons/duocolor/plus-circle.svg';
import Input from 'src/components/Input';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import { showFeaturesModalAtom } from 'src/pages/LibraryV2/Library.atoms';
import Sidebar from '../../Sidebar/Sidebar';
import { SquareLoaders } from '../../SquareLoaders';
import { DirectoryAccordion } from '../DirectoryAccordion/DirectoryAccordion';
import { AriaBaseButton } from '../../Button/BaseButton';
import { Icon } from '../../Icon';
import { useInitiateNewDirectory } from '../useDirectoryActions';

type Props = {
  /**
   * The base path of the current route, for example "/library" for all library routes.
   * This is prepended to the navlinks for each directory/board.
   */
  basePath: string;
};

export const SidebarDirectories: React.FC<Props> = ({
  basePath,
}): JSX.Element => {
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();
  const openNewDirectoryModal = useInitiateNewDirectory();
  const userAndOrg = useUserAndOrganisation();
  const setShowFeaturesModal = useSetAtom(showFeaturesModalAtom);
  const [query, setQuery] = useState('');
  const isTrialExpired = userAndOrg.data?.planStatus === 'trial_expired';

  return (
    <Sidebar.Section
      className="h-full min-h-[200px] flex-auto"
      contentClassName="min-h-0 flex flex-auto"
      title={
        <div className="flex flex-auto flex-row items-center justify-between pr-2.5">
          <span>Boards</span>
          <AriaBaseButton
            onPress={() => {
              if (isTrialExpired) {
                setShowFeaturesModal({
                  isOpen: true,
                  target: 'Paywall Sidebar',
                });
              } else {
                openNewDirectoryModal({ parentDirectoryUuid: null });
              }
            }}
            className="size-5 text-primary"
            icon={
              <Icon>
                <PlusCircle />
              </Icon>
            }
          />
        </div>
      }
    >
      {directoryStructure.isLoading && (
        <SquareLoaders amount={4} className="h-10" />
      )}

      {!directoryStructure.isLoading && (
        <>
          <div className="relative flex min-h-0 flex-auto flex-col overflow-y-auto after:pointer-events-none after:sticky after:bottom-0 after:left-0 after:z-10 after:h-5 after:w-full after:shrink-0 after:bg-gradient-to-b after:from-white/0 after:to-white after:content-['']">
            {directoryStructure.data?.root.map((item) => (
              <SidebarDirectoryNavLink
                parentDirectoryNodeUuid={null}
                basePath={basePath}
                key={item}
                uuid={item}
                level={0}
                query={query}
              />
            ))}
          </div>
          <Input
            onChange={(e) => setQuery(e.target.value)}
            name="filter-boards"
            label=""
            placeholder="Filter boards..."
          />
        </>
      )}
    </Sidebar.Section>
  );
};

type SidebarDirectoryNavLinkProps = {
  uuid: string;
  level: number;
  basePath: string;
  query?: string;
  parentDirectoryNodeUuid: string | null;
};

const SidebarDirectoryNavLink: React.FC<SidebarDirectoryNavLinkProps> = ({
  uuid,
  level,
  basePath,
  query,
  parentDirectoryNodeUuid,
}) => {
  const path = `${basePath}/${uuid}`;
  const match = useMatch(path);
  const directoryStructure =
    trpc.directories.getDirectoryStructureForOrganisation.useQuery();

  const hasChildren =
    !!directoryStructure.data?.directories[uuid].children.length;

  return (
    <DirectoryAccordion
      showMenu
      parentDirectoryNodeUuid={parentDirectoryNodeUuid}
      query={query}
      uuid={uuid}
      to={path}
      level={level}
      name={directoryStructure.data?.directories[uuid]?.name ?? ''}
      isSelected={!!match}
      hasChildDirectories={hasChildren}
      label={
        <span className="font-semibold">
          {directoryStructure.data?.directories[uuid]?.name ?? 'Untitled Board'}
        </span>
      }
    >
      {directoryStructure.data?.directories[uuid].children.map((x) => (
        <SidebarDirectoryNavLink
          query={query}
          parentDirectoryNodeUuid={uuid}
          key={x}
          basePath={basePath}
          uuid={x}
          level={level + 1}
        />
      ))}
    </DirectoryAccordion>
  );
};
