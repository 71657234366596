import { useState, useEffect } from 'react';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import Sidebar from '../Sidebar';

/** @todo Rename this to `TrialCountdownBanner` or something */
const LimitedTimeOffer: React.FC = () => {
  const userAndOrg = useUserAndOrganisation();
  const { LL } = useI18nContext();
  const [hoursLeft, setHoursLeft] = useState(0);
  const [isExpired, setIsExpired] = useState(false);

  const { data: trialExpiryDate } =
    trpc.organisation.upsertTrialExpiryDate.useQuery(undefined, {
      enabled:
        userAndOrg.data?.planStatus === 'trial' ||
        userAndOrg.data?.planStatus === 'trial_expired',
    });

  useEffect(() => {
    if (!trialExpiryDate) return;

    function calculateTimeLeft() {
      if (!trialExpiryDate) return;
      const now = new Date();
      const diffMs = trialExpiryDate.getTime() - now.getTime();
      const diffHours = diffMs / (1000 * 60 * 60);

      if (diffHours <= 0) {
        setHoursLeft(0);
        setIsExpired(true);
      } else {
        setHoursLeft(Math.floor(diffHours));
        setIsExpired(false);
      }
    }

    calculateTimeLeft();

    // Recalculate more frequently when close to expiration
    const intervalTime = hoursLeft > 1 ? 60 * 60 * 1000 : 60 * 1000; // hour : minute
    const interval = setInterval(calculateTimeLeft, intervalTime);

    return () => clearInterval(interval);
  }, [trialExpiryDate, hoursLeft]);

  const offerExpired =
    isExpired || userAndOrg.data?.planStatus === 'trial_expired';

  if (
    !trialExpiryDate ||
    !(
      userAndOrg.data?.planStatus === 'trial' ||
      userAndOrg.data?.planStatus === 'trial_expired'
    )
  )
    return null;

  const daysLeft = Math.floor(hoursLeft / 24);

  const timeRemainingText = offerExpired
    ? LL.upgrade.trialExpired()
    : daysLeft >= 2
      ? LL.upgrade.trialEndsInNDays({ n: daysLeft })
      : hoursLeft >= 24 && hoursLeft < 48
        ? LL.upgrade.trialEndsTomorrow()
        : LL.upgrade.trialEndsToday();

  return (
    <Sidebar.Section>
      <div className="rounded-lg border border-solid border-purple-300 bg-purple-50 p-3 text-primary">
        <p className="text-nowrap font-semibold">{LL.upgrade.proPlanTrial()}</p>
        <p className="text-sm opacity-50">{timeRemainingText}</p>
      </div>
    </Sidebar.Section>
  );
};

export default LimitedTimeOffer;
